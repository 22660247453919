/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.shouldUpdateScroll = ({
  routerProps: { location },
  // getSavedScrollPosition,
}) => {
  // console.log ({location});

  if (location.hash) {
    return false
  }

  return true
}

exports.onServiceWorkerUpdateReady = () => {
  console.log('Please reload to have new experience')
  const reloadMessage = document.querySelector('#reloadMessage')
  const showMessage = new Promise(res => {
    setTimeout(() => {
      reloadMessage.classList.add('swing-in-right-fwd')
      res()
    }, 3000)
  })

  showMessage.then(() => {
    setTimeout(() => reloadMessage.classList.add('swing-out-right-bck'), 12000)
  })
}
/**
 * Redirect old hash contact route
 * to actual contact page
 */

exports.onRouteUpdate = ({ location }) => {
  const isContactHash = location.hash === '#contact'
  isContactHash ? window.location.replace('/contact/') : false
}

// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
exports.onClientEntry = () => {
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
